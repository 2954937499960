import React from 'react';

import ButtonSvgIcon from '../../../buttons/svg-icon/ButtonSvgIcon';
import styles from './CloseButton.module.scss';

interface ICloseButtonProps {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function CloseButton({ className, onClick, disabled }: ICloseButtonProps) {
  return (
    <ButtonSvgIcon
      classNames={ `${styles.CloseButton}${className ? ` ${className}` : ''}` }
      dataTest="modal-close-button"
      onClick={onClick}
      disabled={ disabled }
    >
      <span className="havenly-icon close" />
    </ButtonSvgIcon>
  );
}
