/* istanbul ignore file */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button, { ButtonStyles } from '@components/shared/buttons/Button/Button';
import If from '@components/shared/conditional/If/If';
import shareOnFacebook from '@components/shared/Social/shareOnFacebook';
import getTwitterUrl from '@components/shared/Social/getTwitterUrl';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import CloseButton from '../shared/CloseButton/CloseButton';
import styles from './Share.module.scss';
import EmailIcon from './svg/Email.svg';
import FacebookIcon from './svg/Facebook.svg';
import TwitterIcon from './svg/Twitter.svg';

interface IShareProps {
  modelId: number;
  onClose: () => void;
  shareType: number;
  url: string;
}

interface IShareTypeText {
  [key: number]: string;
}

interface IShareTypeTrack {
  [key: number]: string;
}

type TrackShareMethod = 'facebook' | 'twitter' | 'email' | 'copy' | 'mobile_share';

const SHARE_TYPE_TRACK_MAP: IShareTypeTrack = {
  1: 'product',
  2: 'user_board',
  3: 'designer_board',
  4: 'designer',
  5: 'design_board',
};

const SHARE_TYPE_TEXT_MAP: IShareTypeText = {
  1: 'this product',
  2: 'my inspiration board',
  3: 'this inspiration',
  4: 'this designer',
  5: 'this design',
};

const getAbsoluteUrl = (url: string) => new URL(url, window.location.origin).href;
export function getUrl(url: string) {
  return (url ? getAbsoluteUrl(url) : document.URL);
}

const getTitle = (shareType: number) => {
  const title: IShareTypeText = {
    4: 'Share this designer',
    5: 'Share my design',
  };

  return title[shareType] || 'Share your inspiration';
};

export function getSubject(shareType: number) {
  return `Hey! Check out ${SHARE_TYPE_TEXT_MAP[shareType]} on Havenly ✨`;
}

const getBody = (shareType: number, url: string) => {
  return `Hey! Check out ${SHARE_TYPE_TEXT_MAP[shareType]} from Havenly ✨ ${getUrl(url)}`;
};

export function trackShare(method: TrackShareMethod, shareType: number, modelId: number) {
  analyticsTrack(
    'share_modal',
    {
      method,
      model_id: modelId,
      type: SHARE_TYPE_TRACK_MAP[shareType],
    },
  );
}

export const ShareModal = ({
  modelId,
  onClose,
  shareType,
  url,
}:IShareProps) => {
  const shareFacebook = () => {
    trackShare('facebook', shareType, modelId);
    shareOnFacebook(getUrl(url));
  };

  const shareTwitter = () => {
    trackShare('twitter', shareType, modelId);
    window.open(
      getTwitterUrl(
        getBody(shareType, url),
        null,
        false,
      ), '_blank',
    );
  };

  const shareEmail = () => {
    const subject = getSubject(shareType);
    const body = getBody(shareType, url);
    trackShare('email', shareType, modelId);

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const [copied, setCopied] = useState(false);

  const copyUrl = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    trackShare('copy', shareType, modelId);
    const el = document.createElement('textarea');
    el.value = getUrl(url);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <Modal
      className={ styles.Modal }
      animation
      dialogClassName={ styles.ModalDialog }
      onHide={ onClose }
      show
    >

      <div className={ styles.Root } data-test="share-modal">
        <div className={ styles.CloseButton }>
          <CloseButton
            onClick={ onClose }
          />
        </div>
        <div className={ styles.Content }>
          <div className={ styles.Header }>
            <h2 className={ styles.HeaderText }>
              { getTitle(shareType) }
            </h2>
          </div>
          <div className={ styles.ShareButtons }>
            <Button
              className={ styles.ShareButton }
              buttonStyle={ButtonStyles.secondary}
              disabled={ false }
              onClick={ shareFacebook }
              type="button"
            >
              <i className={ styles.Icon }>
                <FacebookIcon />
              </i>
              Facebook
            </Button>
            <Button
              className={ styles.ShareButton }
              buttonStyle={ButtonStyles.secondary}
              disabled={ false }
              onClick={ shareTwitter }
              type="button"
            >
              <i className={ styles.Icon }>
                <TwitterIcon />
              </i>
              Twitter
            </Button>
            <Button
              className={ styles.ShareButton }
              buttonStyle={ButtonStyles.secondary}
              disabled={ false }
              onClick={ shareEmail }
              type="button"
            >
              <i className={ styles.Icon }>
                <EmailIcon />
              </i>
              Email
            </Button>
          </div>
          <div className={ styles.Copy }>
            <div className={ styles.UrlWrap }>
              <span className={ styles.Url }>
                <a href={getUrl(url)} onClick={ copyUrl } className={ styles.UrlAnchor } data-test="share-url">
                  {getUrl(url)}
                </a>
              </span>

              <Button
                className={ styles.CopyButton }
                buttonStyle={ButtonStyles.primary}
                disabled={ false }
                onClick={ copyUrl }
                type="button"
              >
                <If condition={ copied }>
                  Copied!
                </If>
                <If condition={ !copied }>
                  Copy Link
                </If>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
