import React from 'react';
import classBuilder from 'classnames';

import styles from './ButtonSvgIcon.module.scss';

interface IButtonSvgIconProps {
  type?: 'button' | 'submit' | 'reset';
  children: any;
  active?: boolean;
  disabled?: boolean;
  classNames?: string;
  onClick?: (e: any) => void;
  dataTest?: string;
}

const ButtonSvgIcon = ({
  type = 'button',
  children,
  active,
  disabled,
  classNames,
  onClick,
  dataTest = 'button-svg-icon'
}: IButtonSvgIconProps) => {
  const classes = classBuilder({
    btn: true,
    [styles['a-button-svg-icon']]: true,
    [styles['-active']]: active,
    [styles['-disabled']]: disabled,
  });

  /* eslint-disable react/button-has-type */
  return (
    <button
      type={type}
      className={`${classes} ${classNames}`}
      disabled={disabled}
      data-test={dataTest}
      onClick={onClick}
    >
      {children}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

export default ButtonSvgIcon;
