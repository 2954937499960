import React from 'react';

export enum SHARETYPE {
  PRODUCT= 1,
  USERBOARD= 2,
  DESIGNERBOARD= 3,
  DESIGNER= 4,
  DESIGN_BOARD= 5,
}
export interface IShareModal {
  modelId: number;
  shareType: SHARETYPE;
  url: string;
}

export interface IShareModalContext {
  open: (state: IShareModal) => void;
}

const ShareModalContext = React.createContext<IShareModalContext>({
  open: () => null,
});

export default ShareModalContext;
