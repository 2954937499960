import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export enum ButtonStyles {
  primary,
  secondary
}

interface IButtonProps {
  block?: boolean;
  buttonStyle?: ButtonStyles;
  children: React.ReactNode;
  className?: string;
  'data-auto-test'?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  wide?: boolean;
}

export default function Button({
  block = false,
  buttonStyle = ButtonStyles.primary,
  children,
  className,
  disabled,
  'data-auto-test': dataAutoTest,
  onClick,
  type = 'button',
  wide = false,
}: IButtonProps) {
  const buttonClasses = classNames(
    `${styles['a-button']} btn`,
    { 'h-btn-primary': buttonStyle === ButtonStyles.primary },
    { 'h-btn-secondary': buttonStyle === ButtonStyles.secondary },
    { 'btn-block': block === true },
    { [styles['-wide']]: wide === true },
    className,
  );
  // not sure how to specify the correct type in IButtonProps to get rid of lint error
  return (
    <button
      type={type} // eslint-disable-line react/button-has-type
      data-test="a-button"
      data-auto-test={dataAutoTest}
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
