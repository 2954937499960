import React, { useState } from 'react';
import ShareModalContext, { IShareModal } from '@context/Modal/share-modal-context';
import { trackShare, getSubject, getUrl } from '@components/shared/modals/ShareModal/Share';
import dynamic from 'next/dynamic';

const ShareModal = dynamic(
  () => import('@components/shared/modals/ShareModal/Share'),
  { ssr: false }
);

const ShareModalProvider:React.FunctionComponent = ({ children }) => {
  const [
    shareModalState,
    setShareModalState,
  ] = useState<IShareModal | null>(null);

  if (shareModalState && navigator.share) {
    trackShare('mobile_share', shareModalState.shareType, shareModalState.modelId);
    navigator.share({
      title: getSubject(shareModalState.shareType),
      text: getSubject(shareModalState.shareType),
      url: getUrl(shareModalState.url),
    });
    setShareModalState(null);
  }

  return (
    <ShareModalContext.Provider
      value={{
        open: setShareModalState,
      }}
    >
      {shareModalState && !navigator.share && (
        <ShareModal
          {...shareModalState}
          onClose={() => { setShareModalState(null); }}
        />
      )}
      {children}
    </ShareModalContext.Provider>
  );
};

export default ShareModalProvider;
