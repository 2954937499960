const getTwitterUrl = (text: string, url: string | null = null, includeVia: boolean = true) => {
  if (typeof window === 'undefined') return '';
  const urlArray = [
    'https://twitter.com/intent/tweet?text=',
    window.encodeURIComponent(text),
  ];

  if (url !== null) {
    urlArray.push(
      '&url=',
      window.encodeURIComponent(url),
    );
  }

  if (includeVia === true) urlArray.push('&via=thehavenly');

  return urlArray.join('');
};
export default getTwitterUrl;
