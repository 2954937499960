const shareOnFacebook = (link: string) => {
  // this uses a facebook SDK currently loaded in defaultTemplate

  // this script is not deleteable because it's still used in some places on the site
  // if you remove that script, make sure to migrate the code out
  (window as any).FB.ui({
    method: 'feed',
    link,
  }, () => {});
};
export default shareOnFacebook;
